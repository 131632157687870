<template>
  <v-container style="overflow-x: scroll; max-width: 1280px;">
    <CardUI>
      <template v-slot:header>
        <h4>Ведомость группы</h4>
      </template>
      <template v-slot:body>
        <table class="table_group">
          <tr>
            <td rowspan="2">Студент</td>
            <td rowspan="2">Проект</td>
            <td :colspan="goals.length">Контрольные точки ({{ goals.length }})</td>
          </tr>
          <tr>
            <td v-for="goal in goals" :key="goal.id">
              <div>{{ goal.name }}</div>
              <div style="font-size: 12px">{{ goal.description }}</div>
            </td>
          </tr>
          <tbody v-for="student in students" :key="student.id">
          <tr v-for="(project, index) in student.active_projects" :key="project.id">
            <td v-if="!index" :rowspan="student.active_projects.length">
              <UserLinkComponent :user="student"/>
            </td>
            <td>
              <a target="_blank" :href="'/#/projects/'+project.id"> {{ project.title }}</a>
              <div style="font-size: 10px">
                создан {{ $moment.utc(project.created_at).local().format('DD.MM') }}
              </div>
            </td>
            <td v-for="goal in goals" :key="goal.id">
              <div :key="index"
                   v-for="(item, index) in getGoalFromProjectById(project, goal.id) ? [getGoalFromProjectById(project, goal.id)] : []">
                <GoalProgressBar :goal="item"/>
                <div v-if="item.pivot && !item.pivot.completed_at && item.pivot.deadline">
                  Срок исполнения: {{ $moment.utc(item.pivot.deadline).local().format('DD.MM.YYYY') }}
                </div>
                <div v-if="item.pivot && item.pivot.completed_at">
                  Дата завершения: {{ $moment.utc(item.pivot.completed_at).local().format('DD.MM.YYYY') }}
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="!student.active_projects.length">
            <td>
              <UserLinkComponent :user="student"/>
            </td>
            <td>
              Проекта нет
            </td>
            <td v-for="goal in goals" :key="goal.id">
              -
            </td>
          </tr>
          </tbody>

        </table>
      </template>
    </CardUI>


  </v-container>
</template>

<script>

import opd from "@/api/opd.js";
import UserLinkComponent from "@/components/Profile/UserLinkComponent.vue";
import GoalProgressBar from "@/components/Opd/GoalProgressBar.vue";
import analytics from "@/api/analytics.js";
import CardUI from "@/components/UI/CardUI";

export default {
  components: {CardUI, UserLinkComponent, GoalProgressBar},
  name: "OpdGroupPageComponent",
  data() {
    return {
      students: [],
      goals: []
    }
  },
  methods: {
    getGoalFromProjectById(project, goal_id) {
      return project.goals.filter((g) => g.id === goal_id).shift();
    }

  },
  mounted() {
    if (!this.$store.getters.isAuth) {
      this.$router.push('/auth/login');
      return;
    }
    opd.goals().then((r) => {
      this.goals = r.data;
    }).catch(e => {
      alert(e);
    })

    opd.students(this.$route.params.id).then((r) => {
      this.students = r.data;
      analytics.openOpdGroupPage(this.$store.state.user.currentUser.id);
    }).catch(e => {
      alert(e);
    })
  }
}
</script>

<style scoped>
.table_group td {
  border: 1px solid #eaeaea;
  padding: 10px;
  border-radius: 10px;
}

.table_group {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #eaeaea;
}
</style>
